import { proxy } from 'valtio'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { UseSignalrBuildParams } from '~/modules/SDK/Signalr/useSignalrBuild'

type Value = Partial<{
  [symbolString: string]: Signalr.ValueOfTradeInfo
}>

/**
 * # 專接 TradeInfo
 *
 * @example
 *   useSignalrBuild({
 *     listenOnSignalrEvent: SignalrEvent.TradeInfo,
 *     reconnectCheckInterval: 15000,
 *     valueBuilder: data => {
 *       useSignalrStoreValueTradeInfo_proxy.valueBuilder(data)
 *     },
 *   })
 *
 *   useSignalrStoreValueTradeInfo_proxy.useValueUpdateInterval(1000)
 *
 *   const value = useSnapshot(useSignalrStoreValueTradeInfo_proxy).value
 */
const proxyStore = proxy({
  /**
   * # 所有值
   *
   * ## 需要搭配 useSignalrStoreValueTradeInfo_proxy.useValueUpdateInterval(1000)
   */
  value: {} as Value,

  /** # 內部接值用的，如需優化刷新速度時，可使用 */
  valueRef: {} as Value,

  /** ## 搭配 useSignalrBuild 使用 */
  valueBuilder: ((data: Signalr.ValueOfTradeInfo) => {
    if ('symbol' in data && 'filled_buy_count' in data && 'filled_sell_count' in data) {
      proxyStore.valueRef[data.symbol] = { ...data }
    }
  }) as UseSignalrBuildParams['ValueBuilder'],

  /** # 使用 `.valueRef` 來刷新 `.value` 之間隔時間 */
  useValueUpdateInterval: (intervalMillisecond: number) => {
    useIntervalNow(() => {
      proxyStore.value = { ...proxyStore.valueRef }
    }, intervalMillisecond)
  },
})

export const useSignalrStoreValueTradeInfo_proxy = proxyStore
